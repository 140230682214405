import React from 'react';
import Section from '../components/Section';
import styled from 'styled-components';
import Metadata from '../components/Metadata';
import { StaticImage } from 'gatsby-plugin-image';
import { StyledContainer } from '../layouts/styles';

const StyledHeadingImage = styled.div`
  @media (min-width: 920px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  gap: 3rem;

  p {
    font-size: 1.5rem;
  }
`;

function Kostenübernahme() {
  return (
    <>
      <Metadata title='Kostenübernahme'></Metadata>
      <StyledContainer>
        <h1>Kostenübernahme</h1>
        <Section>
          <StyledHeadingImage>
            <div>
              <p>
                Die Kosten für die Therapie werden, je nach Zuständigkeit, von
                den kommunalen Sozial- oder Jugendämtern sowie den
                Landesjugendämtern übernommen.
              </p>
            </div>
            <StaticImage
              src='../images/Kostenübernahme.png'
              alt='Kugelschreiber in der Hand'
            />
          </StyledHeadingImage>
        </Section>
        <Section>
          <p>
            Die Rechtsgrundlage für{' '}
            <strong>
              autismusspezifische Therapie im Kindes- und Jugendalter
            </strong>{' '}
            bilden § 112 SGB IX i.V.m § 75 SGB IX bzw. § 79 SGB IX in Verbindung
            mit § 113 SGB IX sowie § 35a SGB VIII.
          </p>
          <p>
            Unsere Angebote für{' '}
            <strong>
              (junge) Erwachsene sind eine Leistung der Eingliederungshilfe.
            </strong>{' '}
            Zuständig ist der Träger der Eingliederungshilfe bzw. in einigen
            Fällen der Träger der Jugendhilfe. Hier gibt es verschiedene
            Möglichkeiten der Antragstellung: Autismustherapie als Hilfe zur
            schulischen oder hochschulischen Ausbildung oder Weiterbildung für
            einen Beruf (§ 112 Abs. 1 Satz 1 Nr. 2 SGB IX bzw. i.V. m. § 35a
            Abs. 3, 41 SGB VIII), zur Teilhabe am Arbeitsleben (gemäß § 127 Abs.
            1 SGB III i.V.m. § 49 Abs. 6 SGB IX) oder als Leistungen zur
            sozialen Teilhabe (§§ 113 Abs. 2 Nr. 5 i.V.m. 81 SGB IX oder §§ 113
            Abs. 2 Nr. 6 i.V.m. 82 SGB IX).
          </p>
          <p>Wir unterstützen Sie gerne bei der Antragsstellung.</p>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Kostenübernahme;
